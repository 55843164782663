:root {
  --app-green: #3a7d44;
  --app-orange: #ff7043;
  --app-gold-yellow: #f4c542;
  --app-beige: #f0e6d2;
  --app-charcoal-dark: #2b2b2b;
  --app-gray-medium: #6d6d6d;
  --app-gray-soft: #e0e0e0;
  --app-white: #fff;
  --app-color-editable-view: #03c11f;
  --MAI-linear: linear-gradient(0deg, #03c11f 0%, #fffb00 100%);
  --MAI-Pro-linear: linear-gradient(0deg, #f80 0%, #e2ff04 100%);
  --app-shadow-special-anouncements: 0px 0px 10px 0px #00000080;
  --app-in-business-search: 0px 0px 5px 0px #00000080;
  --app-shadow-mini-listings: 0px 0px 3px 0px #000c;
  --app-shadow-virtical-listings: 0px 0px 5px 0px #00000080;
  --app-shadow-horizontal-listings: 0px 0px 5px 0px #00000080;
  --app-shadow-businesses-search: 0px 0px 3px 0px #000c;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

button {
  cursor: pointer;
  background: none;
  border: none;
}

input {
  border: none;
  border-radius: 0;
}

html, body {
  box-sizing: border-box;
  background: #4e4e4e;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
  overflow: hidden;
}

#map {
  position: absolute;
}

.App {
  box-sizing: border-box;
  border-radius: 0;
  flex-direction: column;
  flex-shrink: 0;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  transition: width .3s ease-in-out, height .3s ease-in-out, border-radius .3s ease-in-out;
  display: flex;
  overflow: hidden;
}

.header {
  background: #3a7d44;
  width: 100%;
  height: 34px;
}

.AppMiniOnDisplay {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  width: 97vw;
  height: 97vh;
}

.ContentContainer0 {
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
  overflow: hidden;
}

.ContentContainer {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.MiniScreenCon {
  box-sizing: border-box;
  z-index: 1000;
  flex-direction: column;
  flex: 1 0 0;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 18px;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  transform: translateY(100vh);
}

.MiniScreenConB {
  background: var(--app-beige, #e0e0e0);
  box-sizing: border-box;
  border-radius: 12px 12px 0 0;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  height: 100%;
  display: flex;
  overflow: hidden;
  box-shadow: 0 -2px 12px 2px #00000040, inset 0 6px 12px #00000040;
}

.MiniScreenConShow {
  opacity: 1;
  transform: translateY(0);
}

.MiniScreenConHide {
  opacity: 1;
  transform: translateY(100vh);
}

.MiniScreenHeader {
  background: var(--app-green, #3a7d44);
  z-index: 1001;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 6px 54px;
  display: flex;
  box-shadow: inset 0 4px 12px #00000040, 0 4px 12px #00000080;
}

.HeaderTitle {
  color: var(--app-white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.MiniScreenContentCon {
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden scroll;
}

.SpinnerMain {
  z-index: 1000;
  background: #000000a3;
  flex-direction: column;
  flex: 1 0 0;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding-top: 32px;
  display: flex;
  position: fixed;
  transform: translateY(100vh);
}

.SpinnerShow {
  opacity: 1;
  transform: translateY(0);
}

.SpinnerHide {
  opacity: 1;
  transform: translateY(100vh);
}

.SpinnerMessage {
  color: var(--app-orange, #ff7043);
  text-align: center;
  letter-spacing: .24px;
  align-self: stretch;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.MainOneOnOverTheOther {
  background: var(--app-white);
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  overflow: hidden;
}

.MainOneOnOverTheOtherInner {
  background: var(--app-white);
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

#TempcontainerForBusinessImagSharing {
  z-index: 1000;
  background: #fff;
  width: 100%;
  display: flex;
  position: absolute;
}

#MainContainerForImgDisplayer, #MainContainerForVideoDisplayer {
  box-sizing: border-box;
  z-index: 1001;
  background-color: #000000e5;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.MyOwnImgDisplayerNotAIsCon1R {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100vw;
  height: auto;
  display: flex;
  overflow: hidden;
}

.MyOwnImgDisplayerNotAIsConIMGR {
  object-fit: contain;
  transform-origin: center;
  width: 100%;
  height: auto;
  max-height: 100vh;
  transition: transform .1s;
}

.MyOwnImgDisplayerNotAIsCon1 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100vw;
  height: auto;
  max-height: 100vh;
  display: flex;
  overflow: hidden;
}

.MyOwnImgDisplayerNotAIsConIMG {
  transform-origin: center;
  object-fit: contain;
  width: 100%;
  height: auto;
  transition: transform .1s;
}

.ExitButtonForImgDisplayer, .ExitButtonForVidDisplayer {
  width: 50px;
  height: 50px;
  color: var(--app-white);
  text-align: center;
  letter-spacing: .24px;
  z-index: 1;
  background: #0000007f;
  border-radius: 12px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  position: absolute;
  top: 20px;
  left: 20px;
}

.miniSpinneForButtons {
  background: #ffffffe2;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  padding: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.VIEW-ALL-CLASS {
  flex-wrap: wrap;
  place-content: center;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 0 6px;
  display: flex;
}

.textEditableViewr {
  background: #ffffff03;
  border: .5px solid #ff7043;
  border-radius: 3px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 4px 0;
  display: flex;
  box-shadow: 0 4px 12px 1px #000c, inset 0 0 12px 4px #ff7043;
}

.notification-container {
  z-index: 9999;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.notification {
  color: #fff;
  opacity: 0;
  cursor: pointer;
  background-color: #333;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  width: 80%;
  max-width: 500px;
  margin: 8px;
  padding: 12px;
  transition: opacity .3s, transform .3s;
  display: flex;
  transform: translateY(-100%);
  box-shadow: 0 4px 6px #0003;
}

.notification.show {
  opacity: 1;
  transform: translateY(0);
}

.notification.hide {
  opacity: 0;
  transform: translateY(-100%);
}

.InnAppNotificationText1 {
  color: #fff;
  align-self: stretch;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.InnAppNotificationText2 {
  color: #fff;
  align-self: stretch;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.InnAppNotificationCon2 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.InnAppNotificationCon3 {
  border: 1px solid var(--app-white, #fff);
  background: var(--MAI-linear, linear-gradient(0deg, #03c11f 0%, #fffb00 100%));
  border-radius: 6px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 4px 6px;
  display: flex;
}

.InnAppNotificationText4 {
  color: var(--app-green, #3a7d44);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.InnAppNotificationCon4 {
  border: 1px solid var(--app-white, #fff);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px;
  display: flex;
}

.PrePageConForInstallationCon {
  visibility: hidden;
  opacity: 0;
  transform-origin: 100% 0;
  box-sizing: border-box;
  width: 100%;
  max-height: 0;
  padding: 0;
  transition: max-height .4s, opacity .4s, transform .4s, visibility 0s linear .4s;
  overflow: hidden;
  transform: scale(0);
}

.PrePageConForInstallationConDisplay {
  visibility: visible;
  opacity: 1;
  max-height: 500px;
  padding: 12px;
  transition: max-height .4s, opacity .4s, transform .4s;
  transform: scale(1);
}

.PrePageConForInstallationConB {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  padding: 0 12px;
  display: none;
}

.PrePageConForInstallationConDisplayB {
  display: flex;
}

.PrePageConForInstallationConClose {
  border: 1px solid var(--app-white, #fff);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px;
  display: flex;
  position: absolute;
  top: 12px;
  right: 12px;
}

.PrePageCon2A2 {
  box-sizing: border-box;
  flex-wrap: wrap;
  place-content: center space-between;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.PrePageCon2A3 {
  border: 1px solid var(--app-beige, #f0e6d2);
  background: var(--app-charcoal-dark, #2b2b2b);
  border-radius: 12px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 6px;
  min-width: 300px;
  max-width: 500px;
  margin: 0 auto;
  padding: 12px;
  display: flex;
  position: relative;
}

.PrePageCon2A3Title {
  color: var(--app-gold-yellow, #f4c542);
  text-align: center;
  font-family: "Rounded Mplus 1c";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.PrePageCon2A4 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  display: flex;
}

.PrePageCon2A4Description {
  color: var(--app-beige, #f0e6d2);
  text-align: center;
  letter-spacing: .36px;
  align-self: stretch;
  font-family: "Rounded Mplus 1c";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.PrePageConMainContent {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 128px;
  padding: 32px 0;
  display: flex;
}

.PrePageCon2A4Button {
  background: var(--app-green, #3a7d44);
  color: var(--app-beige, #f0e6d2);
  text-align: center;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 2px 6px;
  font-family: RocknRoll One;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  box-shadow: 1px 3px 3px #ffffff4d, inset 0 2px 4px #00000040;
}

.mouse-tracking-dot {
  pointer-events: none;
  background-color: #284cff45;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  transition: opacity .3s ease-in-out;
  position: absolute;
}

.click-effect {
  pointer-events: none;
  background-color: #03c11f80;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: .6s forwards ripple-animation;
  position: absolute;
  transform: scale(0);
}

.swipe-trail {
  pointer-events: none;
  background-color: red;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  position: absolute;
}

@keyframes ripple-animation {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(3);
  }
}

.cta-animate {
  animation: 1s ease-in-out infinite alternate bounceScale;
}

@keyframes bounceScale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1)translateY(-5px);
  }

  100% {
    transform: scale(1);
  }
}

.cta-loop {
  animation: 2s ease-in-out infinite pulseGlow;
}

@keyframes pulseGlow {
  0% {
    transform: scale(1);
    box-shadow: 0 0 5px #0085ff4d;
  }

  50% {
    transform: scale(1.02);
    box-shadow: 0 0 15px #0085ff99;
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 5px #0085ff4d;
  }
}
/*# sourceMappingURL=index.c98ac742.css.map */
