:root {
  --app-green: #3a7d44;
  --app-orange: #ff7043;
  --app-gold-yellow: #f4c542;
  --app-beige: #f0e6d2;
  --app-charcoal-dark: #2b2b2b;
  --app-gray-medium: #6d6d6d;
  --app-gray-soft: #e0e0e0;
  --app-white: #fff;
  --app-color-editable-view: #03c11f;
  --MAI-linear: linear-gradient(0deg, #03c11f 0%, #fffb00 100%);
  --MAI-Pro-linear: linear-gradient(0deg, #f80 0%, #e2ff04 100%);
  --app-shadow-special-anouncements: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  --app-in-business-search: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  --app-shadow-mini-listings: 0px 0px 3px 0px rgba(0, 0, 0, 0.8);
  --app-shadow-virtical-listings: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  --app-shadow-horizontal-listings: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  --app-shadow-businesses-search: 0px 0px 3px 0px rgba(0, 0, 0, 0.8);
}
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
button {
  border: none;
  background: none;
  cursor: pointer;
}
input {
  border: none;
  border-radius: 0px;
}
html,
body {
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
  position: absolute;

  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4e4e4e;
  overflow: hidden;
  box-sizing: border-box;
}
#map {
  position: absolute;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 0; /* Initial border-radius */
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    border-radius 0.3s ease-in-out;
  box-sizing: border-box;
}
.header {
  background: #3a7d44;
  width: 100%;
  height: 34px;
}
.AppMiniOnDisplay {
  width: 97vw;
  height: 97vh;
  /* padding-top: 32px; */
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}
.ContentContainer0 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  overflow: hidden;
  /* overflow: scroll; */
}

.ContentContainer {
  display: flex;
  /* position: relative; To allow absolutely positioned children to stack relative to this container */
  width: 100%; /* Ensure it stretches fully */
  height: 100%; /* Adjust as needed for the height of the screen */
  overflow: hidden; /* Optional: Prevent overflow if needed */
  box-sizing: border-box;
}

/* start mini screen */
.MiniScreenCon {
  display: flex;
  padding-top: 18px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  flex: 1 0 0;
  align-self: stretch;

  position: fixed;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.3); */
  /* -webkit-backdrop-filter: blur(2px); */
  /* backdrop-filter: blur(2px); */

  transition: transform 0.3s ease-in-out;
  transform: translateY(100vh);
  /* box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.5) inset; */

  z-index: 1000;
}
.MiniScreenConB {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* flex: 1 0 0; */
  height: 100%;
  align-self: stretch;
  border-radius: 12px 12px 0px 0px;
  background: var(--app-beige, #e0e0e0);
  /* box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.25) inset; */
  box-shadow: 0px -2px 12px 2px rgba(0, 0, 0, 0.25),
    0px 6px 12px 0px rgba(0, 0, 0, 0.25) inset;
  overflow: hidden;
  box-sizing: border-box;
}

.MiniScreenConShow {
  transform: translateY(0vh); /* Move screen to the left */
  opacity: 1;
}
.MiniScreenConHide {
  transform: translateY(100vh); /* Move screen to the left */
  opacity: 1;
}

.MiniScreenHeader {
  display: flex;
  padding: 6px 54px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  background: var(--app-green, #3a7d44);
  /* box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.5) inset; */
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5); */
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25) inset,
    0px 4px 12px 0px rgba(0, 0, 0, 0.5);
  z-index: 1001;
}
.HeaderTitle {
  color: var(--app-white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
/* .MiniScreenContentCon {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  background: var(--app-beige, #e0e0e0);
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 32px;
} */

.MiniScreenContentCon {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex: 1 0 0; */
  width: 100%;
  height: 100%;
  /* align-self: stretch; */

  /* background: var(--app-beige, #e0e0e0); */
  overflow: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
}

/* end mini screen */

/* start spinner */
.SpinnerMain {
  display: flex;
  padding-top: 32px;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  flex: 1 0 0;
  align-self: stretch;

  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.638);

  transform: translateY(100vh);
  z-index: 1000;
}
.SpinnerShow {
  transform: translateY(0vh); /* Move screen to the left */
  opacity: 1;
}
.SpinnerHide {
  transform: translateY(100vh); /* Move screen to the left */
  opacity: 1;
}
.SpinnerMessage {
  align-self: stretch;
  color: var(--app-orange, #ff7043);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
}
/* end spinner */
/* Start Main one over the other */
.MainOneOnOverTheOther {
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  background: var(--app-white);
  overflow: hidden;
}
.MainOneOnOverTheOtherInner {
  height: 100%;
  width: 100%;
  position: absolute;
  background: var(--app-white);
  overflow: hidden;
}
/* End Main one over the other */
#TempcontainerForBusinessImagSharing {
  display: flex;
  position: absolute;
  /* bottom: 0; */
  /* height: 300px; */
  width: 100%;
  background: white;
  z-index: 1000;
}

/* Start Gib Img displayer */

#MainContainerForImgDisplayer {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;

  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.898);
  z-index: 1001;
}
#MainContainerForVideoDisplayer {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;

  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.898);
  z-index: 1001;
}

.MyOwnImgDisplayerNotAIsCon1R {
  width: 100vw; /* Full width of the viewport */
  height: auto; /* Let the height hug the content */
  display: flex;
  justify-content: center; /* Center image horizontally */
  align-items: center; /* Center image vertically */
  align-self: stretch;
  overflow: hidden;
}

.MyOwnImgDisplayerNotAIsConIMGR {
  width: 100%; /* Initial width, 100% of the container */
  height: auto; /* Let height adjust according to width */
  max-height: 100vh; /* Ensure the image never exceeds the height of the viewport */
  object-fit: contain; /* Maintain aspect ratio while scaling */
  transform-origin: center; /* Anchor point for scaling */
  transition: transform 0.1s ease; /* Smooth zoom effect */
}

.MyOwnImgDisplayerNotAIsCon1 {
  width: 100vw; /* Full width of the viewport */
  height: auto; /* Let the height adjust dynamically */
  display: flex;
  justify-content: center; /* Center image horizontally */
  align-items: center; /* Center image vertically */
  align-self: stretch;
  overflow: hidden;
  max-height: 100vh; /* Ensure the container doesn't exceed the screen's height */
}

.MyOwnImgDisplayerNotAIsConIMG {
  width: 100%; /* Image fills the width of the container */
  height: auto; /* Height adjusts proportionally to width */
  transform-origin: center; /* Center for zoom */
  transition: transform 0.1s ease; /* Smooth zoom effect */
  object-fit: contain; /* Maintain aspect ratio while scaling */
}

.ExitButtonForImgDisplayer {
  position: absolute;
  top: 20;
  left: 20;
  height: 50px;
  width: 50px;
  background: #0000007f;

  display: flex;

  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-radius: 12px;
  color: var(--app-white);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  z-index: 1;
}
.ExitButtonForVidDisplayer {
  position: absolute;
  top: 20;
  left: 20;
  height: 50px;
  width: 50px;
  background: #0000007f;

  display: flex;

  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-radius: 12px;
  color: var(--app-white);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  z-index: 1;
}

.miniSpinneForButtons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  padding: 4px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.887);
}
.VIEW-ALL-CLASS {
  display: flex;
  padding: 0px 6px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
}

/* IMPORTANT CLASS EDITABLE VIEW */
.textEditableViewr {
  display: flex;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 3px;
  border: 0.5px solid #ff7043;
  background: rgba(255, 255, 255, 0.01);
  box-shadow: 0px 4px 12px 1px rgba(0, 0, 0, 0.8),
    0px 0px 12px 4px #ff7043 inset;
}

/* IN APP NOTIFICATION */
/* Notification container */
.notification-container {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Individual notification styles */
.notification {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  border-radius: 12px;
  color: white;
  margin: 8px;
  width: 80%;
  border-radius: 12px;
  max-width: 500px;
  opacity: 0;
  background-color: #333;
  transform: translateY(-100%);
  transition: opacity 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* When notification is visible */
.notification.show {
  opacity: 1;
  transform: translateY(0);
}

/* When notification is hidden (sliding up) */
.notification.hide {
  opacity: 0;
  transform: translateY(-100%);
}

/* NEW NOTI */
.InnAppNotificationText1 {
  align-self: stretch;
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.InnAppNotificationText2 {
  align-self: stretch;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.InnAppNotificationCon2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.InnAppNotificationCon3 {
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--app-white, #fff);
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
}
.InnAppNotificationText4 {
  color: var(--app-green, #3a7d44);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.InnAppNotificationCon4 {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid var(--app-white, #fff);
}

/* Instalation Starts Here */
.PrePageConForInstallationCon {
  padding: 0px;

  /* Hidden state */
  visibility: hidden;
  opacity: 0;
  max-height: 0; /* Start collapsed */
  width: 100%;
  overflow: hidden; /* Prevent content overflow during height transition */
  transform: scale(0); /* Shrinks from the top-right corner */
  transform-origin: top right;
  transition: max-height 0.4s ease, opacity 0.4s ease, transform 0.4s ease,
    visibility 0s linear 0.4s; /* Delays visibility change */
  box-sizing: border-box;
}

.PrePageConForInstallationConDisplay {
  padding: 12px;
  /* Expanded state */
  visibility: visible;
  opacity: 1;
  max-height: 500px; /* Adjust based on your content's max height */
  transform: scale(1); /* Expand fully */
  transition: max-height 0.4s ease, opacity 0.4s ease, transform 0.4s ease;
}
.PrePageConForInstallationConB {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 12px;
  align-self: stretch;
}
.PrePageConForInstallationConDisplayB {
  display: flex;
}

.PrePageConForInstallationConClose {
  position: absolute;
  right: 12px;
  top: 12px;

  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid var(--app-white, #fff);
}
.PrePageCon2A2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  /* row-gap: 12px; */
  align-self: stretch;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.PrePageCon2A3 {
  position: relative;
  display: flex;
  min-width: 300px;
  max-width: 500px;
  padding: 12px 12px;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--app-beige, #f0e6d2);
  background: var(--app-charcoal-dark, #2b2b2b);
  margin: 0 auto;
}
.PrePageCon2A3Title {
  color: var(--app-gold-yellow, #f4c542);
  text-align: center;
  font-family: "Rounded Mplus 1c";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.PrePageCon2A4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.PrePageCon2A4Description {
  align-self: stretch;
  color: var(--app-beige, #f0e6d2);
  text-align: center;
  font-family: "Rounded Mplus 1c";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.36px;
}

.PrePageConMainContent {
  display: flex;
  padding: 32px 0px;
  flex-direction: column;
  align-items: center;
  gap: 128px;
  align-self: stretch;
}
.PrePageCon2A4Button {
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 6px;
  background: var(--app-green, #3a7d44);
  box-shadow: 1px 3px 3px 0px rgba(255, 255, 255, 0.3),
    0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;

  color: var(--app-beige, #f0e6d2);
  text-align: center;
  font-family: "RocknRoll One";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* LIVE user actions on screen Start here. */

/* Mouse tracking dot */
.mouse-tracking-dot {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: rgba(40, 76, 255, 0.272);
  border-radius: 50%;
  pointer-events: none; /* Don't block other events */
  transition: opacity 0.3s ease-in-out;
}

/* Click effect (bigger, blueish fading circle) */
.click-effect {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: rgba(3, 193, 31, 0.5); /* Fading blue */
  border-radius: 50%;
  transform: scale(0);
  animation: ripple-animation 600ms forwards;
  pointer-events: none;
}

/* Swipe trail (red line) */
.swipe-trail {
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: red;
  border-radius: 50%;
  pointer-events: none;
}

/* Ripple animation */
@keyframes ripple-animation {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(3);
    opacity: 0;
  }
}

.cta-animate {
  animation: bounceScale 1s ease-in-out infinite alternate;
}

@keyframes bounceScale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1) translateY(-5px);
  }
  100% {
    transform: scale(1);
  }
}

.cta-loop {
  animation: pulseGlow 2s ease-in-out infinite;
}

@keyframes pulseGlow {
  0% {
    transform: scale(1);
    box-shadow: 0 0 5px rgba(0, 133, 255, 0.3);
  }
  50% {
    transform: scale(1.02);
    box-shadow: 0 0 15px rgba(0, 133, 255, 0.6);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 5px rgba(0, 133, 255, 0.3);
  }
}
